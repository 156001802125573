import './App.css';
import React, { Fragment } from 'react';
import DataGrid, { Column, GroupPanel, Paging, FilterRow, HeaderFilter, Grouping, ColumnFixing, Summary, TotalItem, Pager } from 'devextreme-react/data-grid';
import PivotGrid, { FieldChooser, Export } from 'devextreme-react/pivot-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';


import { Host, api, saiki, tanggalIndo } from './Modul';

function App() {
  let Token = localStorage.getItem("TokenData");

  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        D1: saiki(),
        D2: saiki(),
        DataMasuk: [],
        DataKeluar: [],
        Detail: {},
        File: [],
        DocType: "GTPLI",
        path: ""
      };
    }

    async componentDidMount() {
      this.handleMain();
    }

    async handleMain() {
      let sql = await api("/api", { act: "data", D1: this.state.D1, D2: this.state.D2, DocType: this.state.DocType });
      if (sql.status == "sukses") {
        if (this.state.DocType == "GTPLI") {
          this.setState({ DataMasuk: sql.data, path: sql.path });
        } else {
          this.setState({ DataKeluar: sql.data, path: sql.path });
        }
      }
    }

    handleDetail(i) {
      if (i.data.key == undefined) {
        let Detail = i.data;
        let File = JSON.parse(Detail.fileJson);
        this.setState({ Detail: Detail, File: File });
        const myModal = new window.bootstrap.Modal('#modalDetail', {})
        myModal.show('#modalDetail');
      }
    }

    handleChangeType(data) {
      this.setState({ DocType: data });
      setTimeout(() => {
        this.handleMain();
      }, 200);
    }

    handleFile(url) {
      window.open(url);
    }

    handleLogout() {
      localStorage.removeItem("TokenData");
      window.location.reload();
    }

    render() {
      return (
        <Fragment>
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">PXA Operasional</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">Data</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={() => this.handleLogout()}>Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className='container'>
            <ul className="nav nav-pills nav-fill" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => this.handleChangeType('GTPLI')}>Masuk</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => this.handleChangeType('GTPLO')}>Keluar</button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <p></p>
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <div className='d-flex justify-content align-items-center gap-2'>
                  <input type='date' className='form-control' value={this.state.D1} onChange={(e) => this.setState({ D1: e.target.value })} />
                  <input type='date' className='form-control' value={this.state.D2} onChange={(e) => this.setState({ D2: e.target.value })} />
                  <button className='btn btn-primary' onClick={() => this.handleMain()}>Cari</button>
                </div>
                <p></p>
                <div className='table-responsive'>
                  <DataGrid
                    dataSource={this.state.DataMasuk}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    style={{ width: "100%", minWidth: "1000px" }}
                    onRowClick={(e) => this.handleDetail(e)}
                    allowColumnResizing={true} columnAutoWidth={true}
                  >
                    <Column dataField="Docnumber" caption="Docnumber" wordWrap="normal" />
                    <Column dataField="Reason" caption="Reason" wordWrap="normal" />
                    <Column dataField="Weight" caption="Weight" dataType="number" />
                    <Column dataField="VechileID" caption="VechileID" />
                    <Column dataField="Docdate" caption="DocDate" dataType="date" format="dd.MM.yyyy" />
                    <Column dataField="Doctime" caption="DocTime" />
                    <Column dataField="Supplier" caption="Suplier" />
                    <Column dataField="ReffDocnumber" caption="ReffDocnumber" />
                    <Column dataField="Notes" caption="Notes" />
                    <Column dataField="Usr" caption="CreatedBy" />
                    <Column dataField="TimeCreated1" caption="Timecreated" dataType="date" format="dd.MM.yyyy H:i:s" />
                    <Column dataField="TimeUpdated1" caption="Timeupdated" dataType="date" format="dd.MM.yyyy H:i:s" />
                    <Paging defaultPageSize={50} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    <Summary>
                      <TotalItem column="Docnumber" summaryType="count" />
                    </Summary>
                    <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                  </DataGrid>
                </div>
              </div>
              <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                <div className='d-flex justify-content align-items-center gap-2'>
                  <input type='date' className='form-control' value={this.state.D1} onChange={(e) => this.setState({ D1: e.target.value })} />
                  <input type='date' className='form-control' value={this.state.D2} onChange={(e) => this.setState({ D2: e.target.value })} />
                  <button className='btn btn-primary' onClick={() => this.handleMain()}>Cari</button>
                </div>
                <p></p>
                <div className='table-responsive'>
                  <DataGrid
                    dataSource={this.state.DataKeluar}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    style={{ width: "100%", minWidth: "1000px" }}
                    onRowClick={(e) => this.handleDetail(e)}
                    allowColumnResizing={true} columnAutoWidth={true}
                  >
                    <Column dataField="Docnumber" caption="Docnumber" wordWrap="normal" />
                    <Column dataField="Reason" caption="Reason" wordWrap="normal" />
                    <Column dataField="Weight" caption="Weight" dataType="number" />
                    <Column dataField="VechileID" caption="VechileID" />
                    <Column dataField="Docdate" caption="DocDate" dataType="date" format="dd.MM.yyyy" />
                    <Column dataField="Doctime" caption="DocTime" />
                    <Column dataField="Supplier" caption="Suplier" />
                    <Column dataField="ReffDocnumber" caption="ReffDocnumber" />
                    <Column dataField="Notes" caption="Notes" />
                    <Column dataField="Usr" caption="CreatedBy" />
                    <Column dataField="TimeCreated1" caption="Timecreated" dataType="date" format="dd.MM.yyyy H:i:s" />
                    <Column dataField="TimeUpdated1" caption="Timeupdated" dataType="date" format="dd.MM.yyyy H:i:s" />
                    <Paging defaultPageSize={50} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    <Summary>
                      <TotalItem column="Docnumber" summaryType="count" />
                    </Summary>
                    <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                  </DataGrid>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">Detail</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>Docnumber</td>
                          <td>:</td>
                          <td>{this.state.Detail.Docnumber}</td>
                          <td style={{ fontWeight: "bold" }}>Reff Docnumber</td>
                          <td>:</td>
                          <td>{this.state.Detail.ReffDocnumber}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>Docdate</td>
                          <td>:</td>
                          <td>{tanggalIndo(this.state.Detail.Docdate)}</td>
                          <td style={{ fontWeight: "bold" }}>Doctime</td>
                          <td>:</td>
                          <td>{this.state.Detail.Doctime}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>Supplier</td>
                          <td>:</td>
                          <td>{this.state.Detail.Supplier}</td>
                          <td style={{ fontWeight: "bold" }}>ID Card</td>
                          <td>:</td>
                          <td>{this.state.Detail.GuestIdCard}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>VehicleID</td>
                          <td>:</td>
                          <td>{this.state.Detail.VehicleID}</td>
                          <td style={{ fontWeight: "bold" }}>Weight</td>
                          <td>:</td>
                          <td>{this.state.Detail.Weight}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>Reason</td>
                          <td>:</td>
                          <td>{this.state.Detail.Reason}</td>
                          <td style={{ fontWeight: "bold" }}>Notes</td>
                          <td>:</td>
                          <td>{this.state.Detail.Notes}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='row' style={{ overflowY: "scroll", height: "500px", maxHeight: "500px" }}>
                    {
                      this.state.File.map((dd, i) => {
                        return (
                          <div className='col-md-6 mb-2' key={i} onClick={() => this.handleFile(`${Host}/image/${dd.url.replace(this.state.path, '')}`)}>
                            <img src={`${Host}/image/${dd.url.replace(this.state.path, '')}`} width="100%" />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  }

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Username: "",
        Pwd: ""
      };
    }

    async handleLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      btn.disabled = true;
      if (Form.checkValidity()) {
        let sql = await api("/api", { act: "login", Username: this.state.Username, Pwd: this.state.Pwd });
        if (sql.status == "sukses") {
          btn.disabled = false;
          localStorage.setItem("TokenData", sql.Token);
          window.location.reload();
        } else {
          btn.disabled = false;
          alert(sql.pesan);
        }
      } else {
        Form.classList.add('was-validated');
        btn.disabled = false;
      }
    }

    render() {
      return (
        <Fragment>
          <div className='background-container'>
            <div className="container">
              <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card shadow">
                    <div className="card-title text-center border-bottom">
                      <h2 className="p-3">Login</h2>
                    </div>
                    <div className="card-body content">
                      <form onSubmit={(e) => this.handleLogin(e)} noValidate>
                        <div className="mb-4">
                          <label htmlFor="username" className="form-label">Username/Email</label>
                          <input type="text" className="form-control" id="username" value={this.state.Username} onChange={(e) => this.setState({ Username: e.target.value })} required />
                          <div className='invalid-feedback'>Silahkan isi username / Email</div>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="password" className="form-label">Password</label>
                          <input type="password" className="form-control" id="password" value={this.state.Pwd} onChange={(e) => this.setState({ Pwd: e.target.value })} required />
                          <div className='invalid-feedback'>Silahkan isi kata sandi</div>
                        </div>
                        <div className="d-grid">
                          <button type="submit" className="btn text-light btn-primary">Login</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  }

  if (Token) {
    return <Main />;
  } else {
    return <Login />;
  }
}

export default App;
