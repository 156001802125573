export const Host = window.location.hostname == "localhost" ? "http://localhost:2005" : "https://gemaapi.pxa.co.id";

export const api = function (url, dd) {
    dd.Token = localStorage.getItem("TokenData");
    try {
        return new Promise((resolve, reject) => {
            fetch(encodeURI(Host + url), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dd),
            }).then(response => response.json()).then(hasil => {
                resolve(hasil);
            }).catch((error) => {
                reject(error)
            });
        });
    } catch (e) {
        console.log(e);
    }
}

export const saiki = function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
}

export const tanggalIndo = function (data) {
    var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let hasil = [year, month, day].join('-');

    if (hasil == "0000-00-00" || hasil == null) {
        return (hasil);
    } else {
        let BulanIndo = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agust", "Sept", "Okt", "Nov", "Des"];

        let tahun = hasil.substring(2, 4);
        let bulan = hasil.substring(5, 7);
        let tgl = hasil.substring(8, 10);

        let result = `${tgl} ${BulanIndo[bulan - 1]} ${tahun}`;
        return result;
    }
}